html,
body {
  height: 100%;
  margin: 0px;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  /* font-family: 'NunitoSans-Regular'; */
  font-size: 16px;
  line-height: 1;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#root,
.App,
p,
h1,
h2,
h3,
h4 {
  margin: 0px;
}
