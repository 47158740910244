body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NunitoSans-Regular';
  src: local('NunitoSans'), url(./fonts/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Light';
  src: local('NunitoSans'), url(./fonts/NunitoSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-SemiBold';
  src: local('NunitoSans'), url(./fonts/NunitoSans-SemiBold.ttf) format('truetype');
}
